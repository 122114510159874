import React from 'react';
import type { NextPage } from 'next';

import { regionBasedWebsiteSchema } from 'utils/regionBasedWebsiteSchema';
import { Page } from 'ui/components/Page';
import { PageHead } from 'ui/components/PageHead';
import { SanityContentSlot } from 'ui/components/SanityContentSlot';
import { avoidEmptyProperties } from 'utils/object';
import { HomePageContent, HomePageQuery } from 'groq/pages/HomePage';
import { useGroqQuery } from 'hooks/useGroqQuery';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getMappedCountryCode } from 'utils/locale';
import { dyContext } from 'utils/dynamicYield';
import { useDynamicYieldContext } from 'hooks/useDynamicYieldContext';
import { regionBasedSchema } from 'utils/regionBasedSchema';
import { useMeQuery } from '__generated__/graphql';
import { useAuth } from 'hooks/useAuth';
import { useFeature } from 'hooks/useFeature';

const Home: NextPage = () => {
  const auth = useAuth();
  const userQueryDY = useFeature('USER_QUERY_DYNAMIC_YIELD');

  const [pageQuery] = useGroqQuery({
    operationName: 'HomePage',
    query: HomePageQuery,
  });

  const [meResult] = useMeQuery({
    pause: auth.guest || !userQueryDY,
  });

  const { countryCode } = useSiteConfig();
  const me = meResult.data?.me;

  useDynamicYieldContext({ type: dyContext.HOMEPAGE, user: me });

  const mappedCountryCode =
    getMappedCountryCode(countryCode) || countryCode.toLowerCase();

  const page = pageQuery.data?.content as null | HomePageContent;
  const pageTitle = page?.seo?.pageTitle;
  const pageDescription = page?.seo?.pageDescription;
  const schema = regionBasedSchema(mappedCountryCode);
  const websiteSchema = regionBasedWebsiteSchema(mappedCountryCode);

  return (
    <React.Fragment>
      <PageHead title={pageTitle} description={pageDescription}>
        {schema && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(avoidEmptyProperties(schema)),
            }}
          />
        )}
        {websiteSchema && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(avoidEmptyProperties(websiteSchema)),
            }}
          />
        )}
      </PageHead>
      <Page query={pageQuery}>
        <SanityContentSlot
          items={page?.slots}
          slotId="slots"
          documentId={page?._id}
          fullscreen
          headersContent={page?.headersContent}
        />
      </Page>
    </React.Fragment>
  );
};

export default Home;
