import { HeadersContent } from 'groq/global-types';
import { SlotsGroqQuery } from 'groq/ContentSlots';
import { LocaleSeo, LocaleSeoType } from 'groq/shared/LocaleSeo';
import { ComponentWithFilters, Maybe } from '__generated__/graphql-sanity';

export type HomePageContent = {
  _id: string;
  headersContent: HeadersContent;
  seo: LocaleSeoType;
  slots: Maybe<Array<Maybe<ComponentWithFilters>>>;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    HomePage: HomePageContent;
  }
}

export const HomePageQuery = `
  *[_id == "CategoryPage-" + upper($country) + "-root"][0] {
    _id,
    ...(category -> {
      ${LocaleSeo('seo')},
    }),
    "slots": content[] ${SlotsGroqQuery()},
    headersContent,
  }
`;
