import { REGION_INFO } from 'utils/regionBasedConstants';

export function regionBasedWebsiteSchema(countryCode: string) {
  const country = REGION_INFO[countryCode];

  if (!country) {
    return {};
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: `PUMA ${country.name}`,
    alternateName: country.alternateName,
    url: country.url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${country.url}search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
}
